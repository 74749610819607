import React, { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { sendRequestGet } from "../../swr";
import { API_URL_MASTER } from "../../config";
import { Icon } from "@iconify-icon/react";
import useSWR from "swr";
import Loading from "../Loading";
import Datepicker from "react-tailwindcss-datepicker";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const TableDashboardSamsat = () => {
  const { poldaKode } = useParams();
  const query = useQuery();

  const [openRows, setOpenRows] = useState({});

  const [tglMulai, setTglMulai] = useState(query.get("tglMulai"));
  const [tglAkhir, setTglAkhir] = useState(query.get("tglAkhir"));
  const [tglFilter, setTglFilter] = useState({
    startDate: new Date(parseInt(query.get("tglMulai"))).toUTCString(),
    endDate: new Date(parseInt(query.get("tglAkhir"))).toUTCString(),
  });

  const navigate = useNavigate();

  const { data, isLoading } = useSWR(
    `${API_URL_MASTER}/master-report/samsat/${poldaKode}?tglMulai=${tglMulai}&tglAkhir=${tglAkhir}`,
    sendRequestGet,
    {
      errorRetryInterval: 5000,
    }
  );

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const searchData = (e) => {
    e.preventDefault();

    if (tglFilter.startDate !== null && tglFilter.endDate !== null) {
      setTglMulai(new Date(tglFilter.startDate).getTime());
      setTglAkhir(new Date(tglFilter.endDate).getTime());
    }
  };

  // Function to toggle rows
  const toggleRow = (index) => {
    setOpenRows((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  if (isLoading) return <Loading />;
  return (
    <>
      <div className="px-4 mt-10">
        <div className="flex gap-2 justify-between">
          <div className="font-bold text-lg text-black mb-2">DASHBOARD</div>
          <div>
            <p className="text-red-500">{data.lastUpdate}</p>
          </div>
        </div>
        <div className="flex gap-2 flex-wrap">
          <div className="flex flex-col bg-white shadow rounded-lg p-4 flex-grow">
            <span className="font-poppins-regular text-lg font-semibold">
              Total Order (ERI)
            </span>

            <div className="flex items-center justify-between pt-5">
              <span className="text-3xl font-bold">
                {new Intl.NumberFormat("id-ID").format(data.total)}
              </span>
              <div className="h-16">
                <Icon
                  icon="solar:chart-2-bold-duotone"
                  style={{ fontSize: "50px", color: "#e11d48" }}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col bg-white shadow rounded-lg p-4 flex-grow">
            <span className="font-poppins-regular text-lg font-semibold">
              Total Konfirmasi (OCT)
            </span>

            <div className="flex items-center justify-between pt-5">
              <span className="text-3xl font-bold">
                {new Intl.NumberFormat("id-ID").format(data.totalKonfirm)}
              </span>
              <div className="h-16">
                <Icon
                  icon="solar:chart-bold-duotone"
                  style={{ fontSize: "50px", color: "#e11d48" }}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col bg-white shadow rounded-lg p-4 flex-grow">
            <span className="font-poppins-regular text-lg font-semibold">
              Selisih
            </span>

            <div className="flex items-center justify-between pt-5">
              <span className="text-3xl font-bold">
                {new Intl.NumberFormat("id-ID").format(data.totalSelisih)}
              </span>
              <div className="h-16">
                <Icon
                  icon="solar:align-bottom-bold-duotone"
                  style={{ fontSize: "50px", color: "#e11d48" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* tabel dashboard samsat */}
      <div className="grid sm:grid-cols-2 p-4">
        <form onSubmit={searchData} className="flex gap-2 h-10">
          <Datepicker
            inputClassName="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 w-full p-2 font-sans"
            separator="s/d"
            primaryColor={"orange"}
            showShortcuts={true}
            showFooter={true}
            value={tglFilter}
            onChange={(newValue) => setTglFilter(newValue)}
            popoverDirection="down"
          />

          <div className="flex-1 flex flex-row">
            <button
              type="submit"
              className="text-black bg-green-700 hover:bg-green-600 active:bg-green-600 active:text-green-600 focus:ring-4 focus:outline-none focus:ring-[#F9FAFB]/50 rounded-lg text-sm px-5 py-2 font-sans inline-flex items-center mr-2"
            >
              <Icon
                icon="solar:plain-bold-duotone"
                style={{ fontSize: "21px", color: "#ffffff" }}
              />
              <span className="font-sans text-xs ml-2 text-white">Cari</span>
            </button>
          </div>
        </form>
      </div>

      <div className="overflow-auto sm:max-h-[400px] md:max-h-[500px] lg:max-h-[500px] xl:max-h-[500px] 2xl:max-h-[750px]">
        <table className="table w-full font-sans text-sm text-left text-gray-500 border-b">
          <thead className=" text-gray-600 bg-gray-100 sticky top-0">
            <tr>
              <th
                rowSpan="2"
                className="p-2 text-center border-b border-t text-xs"
              >
                NO
              </th>
              <th
                rowSpan="2"
                className="px-2 py-3 text-center border-b border-t text-xs"
                width="320px"
              >
                SAMSAT
              </th>
              <th
                colSpan="3"
                className="px-2 py-3 text-center bg-orange-100 border-b border-t text-xs"
              >
                ORDER TNKB (ERI)
              </th>
              <th
                colSpan="3"
                className="px-2 py-3 text-center bg-green-100 border-b border-t text-xs"
              >
                KONFIRMASI (OCT)
              </th>
              <th
                colSpan="3"
                className="px-2 py-3 text-center bg-blue-100 border-b border-t text-xs"
              >
                TOTAL KEKOSONGAN MATERIAL
              </th>
              <th
                colSpan="3"
                className="px-2 py-3 text-center bg-rose-100 border-b border-t text-xs"
              >
                SELISIH
              </th>
            </tr>
            <tr>
              <th className="text-center w-[90px] border-b border-t text-xs bg-orange-50">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-orange-50">
                R4/LEBIH
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-orange-50">
                TOTAL
              </th>

              <th className="text-center w-[90px] border-b border-t text-xs bg-green-50">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-green-50">
                R4/LEBIH
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-green-50">
                TOTAL
              </th>

              <th className="text-center w-[90px] border-b border-t text-xs bg-blue-50">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-blue-50">
                R4/LEBIH
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-blue-50">
                TOTAL
              </th>

              <th className="text-center w-[90px] border-b border-t text-xs bg-rose-50">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-rose-50">
                R4/LEBIH
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-rose-50">
                TOTAL
              </th>
            </tr>
          </thead>
          <tbody>
            {data.data.map((val, index) => (
              <>
                <tr
                  key={index}
                  className="border-b border-dashed hover:bg-gray-100 text-xs"
                >
                  <td className="p-2 text-center">{index + 1}</td>
                  <td className="px-2 py-2 text-gray-900 flex justify-between items-center">
                    <strong>{val.namaSamsat}</strong>
                    <button
                      type="button"
                      onClick={() => toggleRow(index)}
                      className="text-end"
                    >
                      <Icon
                        icon={
                          openRows[index]
                            ? "solar:square-alt-arrow-up-bold-duotone"
                            : "solar:square-alt-arrow-down-bold-duotone"
                        }
                        style={{
                          fontSize: "21px",
                          color: openRows[index] ? "#f97316" : "#64748b",
                        }}
                      />
                    </button>
                  </td>
                  {/* total push eri */}
                  <td className="p-2 text-center bg-orange-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR2)}
                  </td>
                  <td className="p-2 text-center bg-orange-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR4)}
                  </td>
                  <td className="p-2 text-center bg-orange-50">
                    {new Intl.NumberFormat("id-ID").format(val.total)}
                  </td>

                  {/* total data ter-konfirmasi */}
                  <td className="p-2 text-center bg-green-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR2Konfirm)}
                  </td>
                  <td className="p-2 text-center bg-green-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR4Konfirm)}
                  </td>
                  <td className="p-2 text-center bg-green-50">
                    {new Intl.NumberFormat("id-ID").format(val.totalKonfirm)}
                  </td>

                  {/* total hutang */}
                  <td className="p-2 text-center bg-blue-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR2Hutang)}
                  </td>
                  <td className="p-2 text-center bg-blue-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR4Hutang)}
                  </td>
                  <td className="p-2 text-center bg-blue-50">
                    {new Intl.NumberFormat("id-ID").format(val.totalHutang)}
                  </td>

                  {/* total data selisih */}
                  <td className="p-2 text-center bg-rose-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR2Selisih)}
                  </td>
                  <td className="p-2 text-center bg-rose-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR4Selisih)}
                  </td>
                  <td className="p-2 text-center bg-rose-50">
                    {new Intl.NumberFormat("id-ID").format(val.selisih)}
                  </td>
                </tr>
                {openRows[index] && (
                  <>
                    {/* DATA PER-KATEGORI */}
                    {val.dataKategori.length > 0 && (
                      <>
                        <th
                          colSpan="14"
                          className="py-2 text-center bg-green-200"
                        >
                          TOTAL TNKB PER-KATEGORI
                        </th>
                        {val.dataKategori.map((val2, index) => (
                          <tr
                            key={`rowKategori-${index}`}
                            className="bg-green-50 border-b border-dashed hover:bg-gray-50"
                          >
                            <td colSpan="2" className="p-2 text-right">
                              {val2.kategoriOrder === "UMUM"
                                ? "RANMOR NON BARU"
                                : val2.kategoriOrder === "KHUSUS"
                                ? "RANMOR BARU"
                                : val2.kategoriOrder === "ATENSI"
                                ? "KONTIGENSI"
                                : "NOPIL"}
                            </td>
                            {/* total push eri */}
                            <td className="p-2 text-center bg-orange-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR2
                              )}
                            </td>
                            <td className="p-2 text-center bg-orange-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR4
                              )}
                            </td>
                            <td className="p-2 text-center bg-orange-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.total
                              )}
                            </td>

                            {/* total konfirmasi */}
                            <td className="p-2 text-center bg-green-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR2Konfirm
                              )}
                            </td>
                            <td className="p-2 text-center bg-green-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR4Konfirm
                              )}
                            </td>
                            <td className="p-2 text-center bg-green-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totalKonfirm
                              )}
                            </td>

                            {/* total hutang */}
                            <td className="p-2 text-center bg-blue-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR2Hutang
                              )}
                            </td>
                            <td className="p-2 text-center bg-blue-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR4Hutang
                              )}
                            </td>
                            <td className="p-2 text-center bg-blue-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totalHutang
                              )}
                            </td>

                            {/* total selisih */}
                            <td className="p-2 text-center bg-rose-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR2Selisih
                              )}
                            </td>
                            <td className="p-2 text-center bg-rose-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR4Selisih
                              )}
                            </td>
                            <td className="p-2 text-center bg-rose-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.selisih
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    )}

                    {/* DATA PER-WARNA */}
                    {val.dataWarna.length > 0 && (
                      <>
                        <th
                          colSpan="14"
                          className="py-2 text-center bg-yellow-200"
                        >
                          TOTAL TNKB PER-WARNA
                        </th>
                        {val.dataWarna.map((val2, index) => (
                          <tr
                            key={`rowWarna-${index}`}
                            className="bg-yellow-50 border-b border-dashed hover:bg-gray-50"
                          >
                            {/* total push eri */}
                            <td colSpan="2" className="p-2 text-right">
                              {val2.warnaTnkb}
                            </td>
                            <td className="p-2 text-center bg-orange-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR2
                              )}
                            </td>
                            <td className="p-2 text-center bg-orange-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR4
                              )}
                            </td>
                            <td className="p-2 text-center bg-orange-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.total
                              )}
                            </td>

                            {/* total konfirmasi */}
                            <td className="p-2 text-center bg-green-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR2Konfirm
                              )}
                            </td>
                            <td className="p-2 text-center bg-green-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR4Konfirm
                              )}
                            </td>
                            <td className="p-2 text-center bg-green-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totalKonfirm
                              )}
                            </td>

                            {/* total hutang */}
                            <td className="p-2 text-center bg-blue-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR2Hutang
                              )}
                            </td>
                            <td className="p-2 text-center bg-blue-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR4Hutang
                              )}
                            </td>
                            <td className="p-2 text-center bg-blue-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totalHutang
                              )}
                            </td>

                            {/* total selisih */}
                            <td className="p-2 text-center bg-rose-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR2Selisih
                              )}
                            </td>
                            <td className="p-2 text-center bg-rose-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR4Selisih
                              )}
                            </td>
                            <td className="p-2 text-center bg-rose-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.selisih
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </>
                )}
              </>
            ))}
            <tr>
              <th
                colSpan="2"
                className="px-2 py-3 text-center text-gray-600 bg-gray-100 border-t"
              >
                TOTAL
              </th>
              {/* total push eri */}
              <th className="px-2 py-3 text-center border-b border-t bg-orange-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR2Eri)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-orange-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR4Eri)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-orange-50">
                {new Intl.NumberFormat("id-ID").format(data.total)}
              </th>

              {/* total konfirmasi */}
              <th className="px-2 py-3 text-center border-b border-t bg-green-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR2Konfirm)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-green-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR4Konfirm)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-green-50">
                {new Intl.NumberFormat("id-ID").format(data.totalKonfirm)}
              </th>

              {/* total hutang */}
              <th className="px-2 py-3 text-center border-b border-t bg-blue-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR2Hutang)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-blue-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR4Hutang)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-blue-50">
                {new Intl.NumberFormat("id-ID").format(data.totalHutang)}
              </th>

              {/* total selisih */}
              <th className="px-2 py-3 text-center border-b border-t bg-rose-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR2Selisih)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-rose-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR4Selisih)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-rose-50">
                {new Intl.NumberFormat("id-ID").format(data.totalSelisih)}
              </th>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mt-5 text-xs">
        <div className="ml-4">Keterangan :</div>
        <table className="ml-8">
          <tbody>
            <tr>
              <td>
                <b>ORDER TNKB (ERI)</b>
              </td>
              <td>:</td>
              <td>Order yang dikirim oleh eri.</td>
            </tr>
            <tr>
              <td>
                <b>KONFIRMASI (OCT)</b>
              </td>
              <td>:</td>
              <td>
                Total cetak TNKB yang sudah konfirmasi oleh workshop dengan
                adanya material.
              </td>
            </tr>
            <tr>
              <td>
                <b>KONFIRMASI KEKOSOSNGAN MATERIAL</b>
              </td>
              <td>:</td>
              <td>Total kekosongan material yang dikonfirmasi oleh workshop</td>
            </tr>
            <tr>
              <td>
                <b>SELISIH</b>
              </td>
              <td>:</td>
              <td>Total yang belum dilakukan konfirmasi oleh workshop.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableDashboardSamsat;
